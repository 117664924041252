<template>
    <div class="main-contents display terms">
        <div class="tit">공지사항 등록/수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/></div>
        <div class="sub-tit"><hr><p>공지사항 등록/수정</p><hr></div>
        <div class="banner_box">
            <div class="list">
                <div class="label">노출여부</div>
                <SelectComp title="노출여부" tagName="div" class="radio" type="radio" v-model="noticeInfo.newsCenterExposureYn" list="Y:노출,N:노출종료" rules="required"/>
            </div>
            <div class="list">
                <div class="label">제 목</div>
                <InputComp title="제목" v-model="noticeInfo.newsCenterTitle" maxByte="300" rules="required"/>
            </div>
            <div class="list">
                <div class="label">등록일</div>
                <SelectDate tagNm="div" type="date" class="select-box" classM="wd08" classD="wd08"  v-model="noticeInfo.newsCenterReg" ></SelectDate>
            </div>
            <div class="list matter">
                <div class="label">내 용</div>
                <textarea v-model="noticeInfo.newsCenterCont"></textarea>
            </div>
            <div class="list bn_img">
                <div class="label">이미지</div>
                <file-select title="이미지" tagNm="div" type="admin" v-model="temp.noticeImg" :name="noticeInfo.attaFileNm" accept=".jpg,.jpeg,.png,.bmp,.gif" />
            </div>
        </div>
        <div class="select_btn">
			<div class="btn_01" @click="$router.go(-1)">취소</div>
			<div class="btn_02" v-if="paramSeq == ''" @click="save()">등록</div>
            <div class="btn_02" v-else style="background-color: #FF681D;" @click="save()">수정</div>
		</div>
    </div>
</template>

<script>
import fileSelect from "@/components/FileSelect.vue";
export default {
    components: { fileSelect },
    data() {
        return {
            noticeInfo : {
                newsCenterSeq : '',
                newsCenterExposureYn : '',
                newsCenterTitle : '',
                newsCenterReg : '',
                newsCenterCont : '',
                attaFileSeq : '',
                attaFileYn : '',
                attaFileNm : ''
            },
            temp : {},

            paramSeq : this.$route.params.newsCenterSeq || '',
        }
    },
    beforeMount() {
        //if(this.$route.params.newsCenterSeq) {
            this.$.httpPost('/api/main/bbs/adm/notice/getNoticeInfo', {newsCenterSeq : this.paramSeq})
          .then(res=>{
                this.noticeInfo = res.data.info || {};
          }).catch(this.$.httpErrorCommon);
       // }
    },
    methods: {
        save() {
            var files = [];
            if(this.temp.noticeImg instanceof File){
				files.push(this.temp.noticeImg);
                //console.log(this.temp.noticeImg);
                //this.data.bannerImgFileNm = this.temp.bannerImg.name;
				this.noticeInfo.attaFileYn = 'Y';
			}
            if (this.paramSeq == '') {
                this.$.popup('/adm/bbs/BBS901P03')
                .then(res => {
                    if(res) {
                        this.$.fileUpload('IMG', files, '/api/main/bbs/adm/notice/setNotice', this.noticeInfo)
                        .then(() => {
                            alert('저장이 완료되었습니다.');
                            this.$router.go(-1);
                        }).catch(this.$.httpErrorCommon);
                    }    
                });
            } else {
                this.$.popup('/adm/bbs/BBS901P04')
                .then(res => {
                    if(res) {
                        this.$.fileUpload('IMG', files, '/api/main/bbs/adm/notice/setNotice', this.noticeInfo)
                        .then(() => {
                            alert('수정이 완료되었습니다.');
                            this.$router.go(-1);
                        }).catch(this.$.httpErrorCommon);
                    }    
                });
            }
            
            
        }
    },
}
</script>